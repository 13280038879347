<app-header></app-header>
<app-chat-image *ngIf="isLoggedIn"></app-chat-image>
<div style="height: 100vh; background-image: url(/assets/img/loan.jpg); background-size: cover; background-position: center;" class="position-relative w-100">
    <div class="position-absolute text-white d-flex flex-column align-items-start justify-content-center" style="top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0,0,0,.7);">
        <div class="container">
            <div class="col-md-12" style="text-align: center;">
                <!-- <span style="color: #bbb;" class="text-uppercase">SubHeadline</span> -->
                <!-- on small screens remove display-4 -->
                <h1 class="mb-4 mt-2 display-4 font-weight-bold"><span style="color: #c60c0c;">MyServices-Highway</span></h1>
                <p style="color: #bbb;"> This fintech platform enables the customers to own their data fully under all times and control the excess to the data while obtaining financial services. <br> This platform enable the customers to fullfill their financial needs by handholding
                    them throughout the process and is not an year distribution / aggregator platform. This platform uses the unique blockchain platform to ensure the privacy and protection of the data during storage and transmission and processing.
                </p>
                <div class="mt-5">
                    <!-- hover background-color: white; color: black; -->
                    <a [routerLink]="['/signin']" class="btn px-5 py-3 text-white mt-3 mt-sm-0" style="border-radius: 30px; background-color: #c60c0c;">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>


<section id="services" class="bg-light pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <h2 class="title">What <span>Services</span> We Offer
                </h2>
                <p class=" mt-3 ">MyServices-Highway is the newest kid on the block. Armed with blockchain security, this forum provides all types of loans.
                    <br> Enabling all typee of funded and non funded financial and investment services like savings , investments, borrowing, insurance and advisering services.
                    <br> Customer profolio aggregation and diversification.
                </p>
                <a class="btn btn-primary my-5" href="#">More Info </a>
            </div>
            <div class="col-lg-7">
                <div class="row card-items">
                    <div class="col-lg-6 col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <i class="fa fa-university" aria-hidden="true"></i>
                                <h5 class="card-title"> Impregnable Privacy</h5>
                                <p class="card-text">Financial</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <i class="fa fa-university" aria-hidden="true"></i>
                                <h5 class="card-title">Confidentiality/Trust</h5>
                                <p class="card-text">Low Pricing Fees</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <i class="fa fa-university" aria-hidden="true"></i>
                                <h5 class="card-title"> Reliability</h5>
                                <p class="card-text">Competitive Pricing</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <i class="fa fa-university" aria-hidden="true"></i>
                                <h5 class="card-title"> Personalization</h5>
                                <p class="card-text">Wide Range Offering</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="extension container--pall">
    <div class="container">
        <div class="extension__info container--px">
            <h1>About us</h1>

        </div>

        <div class="extension__browsers-cards">
            <div class="extension__card">
                <img src="assets/img/fin1.png" alt="Google Chrome Logo">
                <div class="card__details">
                    <h3>Advisors</h3>
                    <span>Income tax<br>Portfolio Mannagement<br> Foriegn Exchange </span>
                </div>

                <button type="button" class="btn btn-primary">More</button>
            </div>

            <div class="extension__card" [routerLink]="['/loanproducts']">
                <img src="assets/img/fin2.png" alt="Google Chrome Logo">
                <div class="card__details">
                    <h3>Loan Products</h3>
                    <span>Personal <br>Home <br> Vehicle</span>
                </div>
                <!-- <div class="card__separate"></div> -->
                <button type="button" class="btn btn-primary">More</button>
            </div>

            <div class="extension__card">
                <img src="assets/img/fin3.png" alt="Google Chrome Logo">
                <div class="card__details">
                    <h3>Capital Market Products</h3>
                    <span>Mutual fund<br>Equities<br> Bonds</span>
                </div>

                <button type="button" class="btn btn-primary">More</button>
            </div>
        </div>
    </div>
</section>



<section id="services" class="bg-light pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <h2 class="title">Srichid <span>MyServices Highway</span>
                </h2>
                <p class=" mt-3 "></p>
                <a class="btn btn-primary my-5" [routerLink]="['/signin']">Register Here</a>
            </div>
            <div class="hero__image"></div>
        </div>
    </div>
</section>






<!-- --------------------- -->
<!-- Site footer -->
<footer class="site-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <h6>Company</h6>
                <br>
                <p class="text-justify">
                    # 563, "Srichid", II Cross, II Main,<br /> RBI East Layout, VII Phase, J P Nagar, <br /> Bangalore - 560078, Karnataka, India.<br /><br />
                    <strong>Phone:</strong> +91-80-26852070<br />
                    <strong>Email:</strong>info@srichidtechnologies.com<br />
                </p>
                <div class="social-links mt-3">
                    <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a href="https://www.facebook.com/" class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                    <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i ></a>
                </div>
            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Useful Links</h6>
                <br>
                <ul class="footer-links">
                    <li>
                        <i class="bx bx-chevron-right"></i> <a href="#">Home</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i> <a href="#">About us</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i> <a href="#">Services</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Terms of Service</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Privacy Policy</a>
                    </li>
                </ul>

            </div>

            <div class="col-xs-6 col-md-3">
                <h6>Our Services</h6>
                <br>
                <ul class="footer-links">

                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Digital Lending Enablement</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Savings and Investments services</a>
                    </li>
                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Analyticals and advisory services</a>
                    </li>

                    <li>
                        <i class="bx bx-chevron-right"></i>
                        <a href="#">Pension</a>
                    </li>

                </ul>
            </div>
        </div>
        <hr>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
                <p class="copyright-text">Copyright &copy;  {{copyrightdate | date: 'yyyy'}} Srichid Technologies. All Rights Reserved
                </p>
            </div>

            <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">


                </ul>
            </div>
        </div>
    </div>
</footer>
<!-- ======= Footer ======= -->