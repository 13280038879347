import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../Shared/UserMaster/user.service';
import { UserDetailsService } from '../Shared/UserDetails/user-details.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isLoggedIn: boolean;
  insurancelist: any;
  copyrightdate : Date = new Date();
  constructor( private router: Router,private spinner:NgxSpinnerService,private userservice:UserDetailsService) {
    
    var nav = document.getElementById("navv");
    if (nav != null) {
      nav.style.visibility = "show";
    }
   }

  ngOnInit() {
    this.GetallInsuranceList();
      document.addEventListener(
      'DOMContentLoaded',
      function () {
        setTimeout(function () {
          document.getElementById('hero-image').className = 'shrink';
        }, 100);
      },
      false
    );
  }

  Logout() {
    this.isLoggedIn = false;
    localStorage.setItem("IsLoggedIn", "false");
    localStorage.removeItem('userToken');
    this.router.navigate(['/']);
    localStorage.clear();
  }

  openproduct(){
    this.router.navigate(['/loanproducts']);
  }
  SendRequest(id){
    if (localStorage.getItem('IsLoggedIn') != "true") {
      localStorage.setItem('selectedinsurancetype',id);
      this.router.navigate(['/signin']);
    }

    if (localStorage.getItem('IsLoggedIn') == "true") {
      localStorage.setItem('selectedinsurancetype',id);
      this.router.navigate(['/insurancesp']);
    }
  }
  GetallInsuranceList() {
   // this.spinner.show()
    this.userservice.getinsurancelist().subscribe((data: any) => {
      this.insurancelist = data;
     // this.spinner.hide()
    })
  }
  
  OpenSPlist(id){
    if (localStorage.getItem('IsLoggedIn') != "true") {
      localStorage.setItem('selectedloantype',id);
      this.router.navigate(['/signin']);
    }

    if (localStorage.getItem('IsLoggedIn') == "true") {
      localStorage.setItem('selectedloantype',id);
      this.router.navigate(['/loansp']);
    }
  }
}
